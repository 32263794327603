<template>
  <div>
    <el-dialog
        title="咨询客服"
        :visible.sync="dialogVisible"
        width="6rem"
        :before-close="handleClose">
        <div class="content">
          <div class="content-info">客服电话：<span>13708206115</span></div>
          <div class="content-info">客服邮箱：<span>wen.luo@newtouch.com</span></div>
          <div class="content-info img-box">
            <div>
              <p>企业微信</p>
              <p class="color-f">加微了解更多</p>
              <p class="color-f">还有惊喜！</p>
              <p class="color-f">赢取福利包→</p>
            </div>
            <img src="/imgs/er-wei-ma02.jpg"/>
          </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {Dialog} from 'element-ui'
export default {
  name: "consultModalMobile",
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    showDialog() {
      this.dialogVisible = true
    },

    handleClose() {
      this.dialogVisible = false;
    },

  }

}
</script>

<style scoped lang="scss">
@import "../../assets/scss/base";
@import "../../assets/scss/config";
@import "../../assets/scss/mixin";
/deep/ .el-dialog__close::before {
  line-height: .6rem;
  font-size: .3rem;
}
/deep/ .el-dialog__header .el-dialog__title {
  line-height: .5rem;
  font-size: .3rem !important;
}
.content {
  font-size: .24rem;
  padding: 0 .32rem;
  .content-info {
    margin-bottom: 16px;
    span {
      display: inline-block;
      margin-left: 86px;
    }
  }
  .img-box {
    div,img {
      display: inline-block;
      vertical-align: middle;
    }
    div {
      margin-right: 68px;
    }
    img {
      text-align: right;
      width: 2.4rem;
    }
    .color-f {
      color: $colorF3;
    }
  }
}
</style>
