<template>
        <div class="content_connect_box" id="content_connect_box">
        <div>
          <div class="connect-title">联系方式</div>
          <div class="connect_content">
            <div class="connect-item line-one">
              <span>成立时间: {{companyInfoData.foundingTime}}</span>
              <span>注册资金: {{companyInfoData.registeredFunds}}</span>
              <span>企业规模: {{companyInfoData.companyScale}}</span>
            </div>
            <div class="connect-item line-two">
              <span>联系电话: {{companyInfoData.customerWay}}</span>
              <span>客服电话: {{companyInfoData.customerWay}}</span>
            </div>
            <div class="connect-item">
              <span>企业邮箱: {{companyInfoData.email}}</span>
            </div>
            <div class="connect-item">
              <span>企业官网: {{companyInfoData.companyWebsite}}</span>
            </div>
            <div class="connect-item">
              <span>企业地址: {{companyInfoData.address}}</span>
            </div>
          </div>
          <div class="divide"></div>
          <div class="connect-footer">
            <img v-if="localStorageType==1" class="connect-img" src="/imgs/logo_white_yl.png"/>
            <img v-if="localStorageType==2" class="connect-img" src="/imgs/logo_white_qy.png"/>
            <div class="connect-inquiry">咨询: {{ companyInfoData.customerWay }}</div>
            <div class="connect-link">
					<dl v-for="(item, index) in footer_bottomdata" :key="index">
						<!-- <dt>{{ item.title }}</dt>-->
						<dd v-for="(item, index) in item.data" :key="index">
							<a :href="item.url" target="_blank">{{ item.name }}</a>
						</dd>
					</dl>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { getCompanyInfo } from "../api/product";
export default {
  data() {
    return {
      companyInfoData:{},
			footer_bottomdata: [
				{
					title: "", data: [
						{
							name: '天天数链',
							url: 'https://www.tntlinking.com'
						}
					]
				},
				{
					title: "", data: [
						{
							name: '新致软件',
							url: 'https://www.newtouch.com'
						}
					]
				},
				{
					title: "", data: [
						{
							/*name: '医盟网',
							url: 'http://chisc.net/'*/
						}
					]
				},
				{
					title: "", data: [
						{
							/*name: 'Q医疗',
							url: 'http://www.qyiliao.com/'*/
						}
					]
				},
			],
      localStorageType: localStorage.getItem("type") || 1,
    }
  },
  mounted() {
    getCompanyInfo({companyId:449}).then(res => {
      if(res.code === 200) {
        this.companyInfoData = res.data
        this.companyInfoData.companyLogo = this.companyInfoData.companyLogo? this.companyInfoData.companyLogo: '/imgs/product/companyLogo.png'
      }
    })
  }
}
</script>


<style lang='scss' scoped>
  .content_connect_box {
    padding: .68rem .36rem .9rem;
    font-size: .24rem;
    font-weight: 400;
    color: #fff;
    background-color: #080D3A;
    border-radius: 0 0 8px 8px;
    .connect-title {
      text-align: center;
      padding-bottom: .62rem;
      font-size: .36rem;
      font-weight: 500;
      line-height: .5rem;
    }

    .connect_content {
      .connect-item {
        display: flex;
        padding-bottom: .3rem;
        &:last-child {
          padding-bottom: 0;
        }
      }
      .line-one {
        justify-content: space-between;
      }
    }

    .divide {
      margin: .55rem 0;
      height: 1px;
      width: 100%;
      background-color: #979797;
    }
    .connect-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      .connect-img {
        width: 3.34rem;
      }
      .connect-inquiry {
        padding: .2rem 0 .6rem;
      }
      .connect-link {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  
	dl {
		margin: 0;

		dd {
			margin: 0;
      font-size: .24rem;
			line-height: .33rem;

			a {
				width: 100%;
				display: inline-block;
				color: #fff;

				&:visited {
					color: #fff;
				}
			}
		}
	}
</style>
